html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, menu, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --foreground: black;
  --background: white;
  --gray: #0000000d;
  --dark-gray: #00000040;
}

body {
  cursor: pointer;
  background: var(--background);
  color: var(--foreground);
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}

body.dark {
  --foreground: white;
  --background: black;
  --gray: #ffffff1a;
  --dark-gray: #ffffff1a;
}

body main, body main canvas#grid, body main section#board {
  position: absolute;
}

body main section#board.active {
  -webkit-user-select: none !important;
  user-select: none !important;
}

body main section#board div#selection {
  background-color: var(--gray);
  position: absolute;
}

body main section#board div.memo {
  background-color: var(--background);
  border: 1px solid var(--foreground);
  -webkit-user-select: none;
  user-select: none;
  min-width: 80px;
  min-height: 80px;
  position: absolute;
}

body main section#board div.memo.active {
  -webkit-user-select: auto;
  user-select: auto;
  box-shadow: 10px 10px var(--dark-gray);
  -webkit-user-select: none !important;
  user-select: none !important;
}

body main section#board div.memo.active div.close, body main section#board div.memo.active textarea.input {
  -webkit-user-select: none !important;
  user-select: none !important;
}

body main section#board div.memo div.close {
  color: var(--foreground);
  text-align: center;
  border-bottom: 1px solid var(--foreground);
  width: 16px;
  height: 16px;
  font-size: 10px;
  font-weight: normal;
  line-height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

body main section#board div.memo div.drag {
  cursor: grab;
  border-bottom: 1px solid var(--foreground);
  width: 100%;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

body main section#board div.memo textarea.input {
  resize: none;
  cursor: text;
  background: var(--background);
  color: var(--foreground);
  scrollbar-color: var(--foreground) transparent;
  scrollbar-width: thin;
  border: 0;
  outline: 0;
  width: calc(100% - 32px);
  height: calc(100% - 49px);
  margin: 0;
  padding: 16px;
  font-family: monospace;
  font-size: 13px;
  position: absolute;
  top: 17px;
  left: 0;
  overflow: auto;
}

body main section#board div.memo textarea.input::-webkit-scrollbar {
  width: 1px;
  height: 6px;
}

body main section#board div.memo textarea.input::-webkit-scrollbar-track {
  background: none;
  margin-top: 8px;
  margin-bottom: 13px;
}

body main section#board div.memo textarea.input::-webkit-scrollbar-thumb {
  background: var(--foreground);
}

body main section#board div.memo textarea.input::-webkit-scrollbar-thumb:hover {
  background: var(--foreground);
}

body main section#board div.memo textarea.input::-webkit-scrollbar-thumb:active {
  background: var(--foreground);
}

body main section#board div.memo textarea.input::placeholder {
  color: #0006;
}

body main section#board div.memo div.resize {
  cursor: nw-resize;
  border-top: 1px solid var(--foreground);
  border-left: 1px solid var(--foreground);
  background-color: #0000;
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: 0;
  right: 0;
}
/*# sourceMappingURL=index.a44b20e5.css.map */
