@use "reset";

:root {
    --foreground: black;
    --background: white;
    --gray: rgba(0, 0, 0, 0.05);
    --dark-gray: rgba(0, 0, 0, 0.25);
}

body {
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    cursor: pointer;
    background: var(--background);
    color: var(--foreground);

    &.dark {
        --foreground: white;
        --background: black;
        --gray: rgba(255, 255, 255, 0.1);
        --dark-gray: rgba(255, 255, 255, 0.1);
    }

    main {
        position: absolute;

        canvas#grid {
            position: absolute;
        }

        section#board {
            position: absolute;

            &.active {
                user-select: none !important;
                -moz-user-select: none !important;
                -webkit-user-select: none !important;
                -ms-user-select: none !important;
            }

            div#selection {
                position: absolute;
                background-color: var(--gray);
            }

            div.memo {
                background-color: var(--background);
                position: absolute;
                min-width: 80px;
                min-height: 80px;
                border: 1px solid var(--foreground);
                user-select: none;

                &.active {
                    user-select: auto;
                    box-shadow: 10px 10px var(--dark-gray);
                    user-select: none !important;
                    -moz-user-select: none !important;
                    -webkit-user-select: none !important;
                    -ms-user-select: none !important;

                    div.close, textarea.input {
                        user-select: none !important;
                        -moz-user-select: none !important;
                        -webkit-user-select: none !important;
                        -ms-user-select: none !important;
                    }
                }
                div.close {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    color: var(--foreground);
                    font-size: 10px;
                    font-weight: normal;
                    text-align: center;
                    line-height: 15px;
                    width: 16px;
                    height: 16px;
                    border-bottom: 1px solid var(--foreground);
                }

                div.drag {
                    cursor: grab;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 100%;
                    height: 16px;
                    border-bottom: 1px solid var(--foreground);
                }

                textarea.input {
                    position: absolute;
                    top: 17px;
                    left: 0px;
                    width: calc(100% - 32px);
                    height: calc(100% - 49px);
                    padding: 16px;
                    margin: 0;
                    border: 0;
                    outline: 0;
                    resize: none;
                    overflow: auto;
                    font-family: monospace;
                    font-size: 13px;
                    cursor: text;
                    background: var(--background);
                    color: var(--foreground);

                    scrollbar-color:var(--foreground) transparent;
                    scrollbar-width: thin;

                    &::-webkit-scrollbar {
                        width: 1px;
                        height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                        margin-top: 8px;
                        margin-bottom: 13px;
                        background: transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: var(--foreground);
                    }
                    &::-webkit-scrollbar-thumb:hover{
                        background: var(--foreground);
                    }
                    &::-webkit-scrollbar-thumb:active{
                        background: var(--foreground);
                    }

                    &::placeholder {
                        color: rgba(0, 0, 0, 0.4);
                    }
                }

                div.resize {
                    cursor: nw-resize;
                    position: absolute;
                    background-color: transparent;
                    right: 0px;
                    bottom: 0px;
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid var(--foreground);
                    border-left: 1px solid var(--foreground);
                }
            }
        }
    }
}
